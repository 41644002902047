<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'CustomerLevelsList' }">
              會員等級列表
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
                規則設定
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
    <div class="row d-flex mb-2 mb-xl-2 justify-content-between">
      <div class="col-12 col-xl-6 d-flex align-items-center">
        <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">規則設定</h4>
      </div>
      <div
        class="
          col-12 col-xl-6
          d-flex
          justify-content-start justify-content-xl-end
        "
      >
        <b-button
          v-if="isShow && checkPermission([consts.CUSTOMER_LEVEL_AUTO_CHANGE_EDIT])"
          class="mb-2 mb-xl-0 ml-2 py-2"
          variant="primary"
          @click="isShow = !isShow"
          ><i class="mdi mdi-lead-pencil" aria-hidden="true"></i>編輯</b-button
        >
      </div>
    </div>

      <b-overlay
        :show="isLoading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
      >
        <!-- 會員升級條件 -->
        <section class="row mb-5">
          <div class="col-12 col-xl-8">
            <h4 class="mb-4 text-primary">會員升級條件</h4>
            <small>當滿足以下條件，客戶將被自動增加/升級到此會員級別:</small>
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="升級條件"
              label-for="role"
            >
              <b-form-select
                id="name"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="roleOptions"
                v-model="form.base.role"
                :state="v$.form.base.role.$error ? false : null"
                :disabled="isShow"
              ></b-form-select>
              <b-form-invalid-feedback :state="!v$.form.base.role.$error"
                >此欄位為必填</b-form-invalid-feedback
              >
            </b-form-group>
            <!-- type ==2 每年週期 -->
            <b-form-group
              v-if="form.base.role == 2"
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="每年"
              label-for="year-period"
            >
              <div class="d-flex align-items-center">
                <b-form-select v-model="form.base.start_month" :options="monthOptions" class="mr-2" :disabled="isShow"></b-form-select>
                <span>月</span>
                <b-form-select v-model="form.base.start_day" :options="baseStartDayOptions" class="mx-2" :disabled="isShow"></b-form-select>
                <span>日</span>
                <span class="mx-2">~</span>
                <b-form-select v-model="form.base.end_month" :options="monthOptions" class="mr-2" :disabled="isShow"></b-form-select>
                <span>月</span>
                <b-form-select v-model="form.base.end_day" :options="baseEndDayOptions" class="mx-2" :disabled="isShow"></b-form-select>
                <span>日</span>
              </div>
            </b-form-group>
            <!-- type == 3 月週期 -->
            <b-form-group
              v-if="form.base.role == 3"
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="指定期限"
              label-for="period"
            >
              <b-input-group>
              <b-form-input v-model="form.base.period" type="number" min="0" :disabled="isShow"></b-form-input>
                <b-input-group-append>
                  <b-input-group-text>個月內</b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="總消費額"
              label-for="amount"
            >
              <b-input-group prepend="NT$">
                <b-form-input
                  id="name"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="number"
                  min="0"
                  v-model="form.base.amount"
                  :state="v$.form.base.amount.$error ? false : null"
                  :disabled="isShow"
                ></b-form-input>
              </b-input-group>
              <b-form-invalid-feedback :state="!v$.form.base.amount.$error"
                >此欄位為必填</b-form-invalid-feedback
              >
            </b-form-group>
            <small>*注意 : 當顧客訂單完成付款後，系統將於隔日凌晨依照升級條件計算會員等級。</small>
          </div>
        </section>
        <!-- 會員有效期限 -->
        <section class="row mb-5">
          <div class="col-12 col-xl-8">
            <h4 class="mb-4 text-primary">會員有效期限</h4>
            <b-form-group>
              <b-form-radio-group
              v-model="form.validity_permanent"
              :options="validityOptions"
              stacked
              :disabled="isShow"
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group v-if="form.validity_permanent == 0">
                <b-input-group>
                <b-form-input
                    v-model="form.validity_period"
                    type="number"
                    min="1"
                    :disabled="isShow"
                ></b-form-input>
                <b-input-group-append>
                    <b-input-group-text>個月</b-input-group-text>
                </b-input-group-append>
                </b-input-group>
            </b-form-group>
          </div>
        </section>
        <!-- 會員續約條件 -->
        <section class="row mb-5">
          <div class="col-12 col-xl-8">
            <h4 class="mb-4 text-primary">會員續約條件</h4>
            <small>當滿足以下條件，客戶將被自動增加/升級到此會員級別:</small>
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="續約條件"
              label-for="role"
            >
              <b-form-select
                id="name"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="roleOptions"
                v-model="form.renew.role"
                :state="v$.form.renew.role.$error ? false : null"
                :disabled="isShow"
              ></b-form-select>
              <b-form-invalid-feedback :state="!v$.form.renew.role.$error"
                >此欄位為必填</b-form-invalid-feedback
              >
            </b-form-group>
            <!-- type ==2 每年週期 -->
            <b-form-group
              v-if="form.renew.role == 2"
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="每年"
              label-for="year-period"
            >
              <div class="d-flex align-items-center">
                <b-form-select v-model="form.renew.start_month" :options="monthOptions" class="mr-2" :disabled="isShow"></b-form-select>
                <span>月</span>
                <b-form-select v-model="form.renew.start_day" :options="renewStartDayOptions" class="mx-2" :disabled="isShow"></b-form-select>
                <span>日</span>
                <span class="mx-2">~</span>
                <b-form-select v-model="form.renew.end_month" :options="monthOptions" class="mr-2" :disabled="isShow"></b-form-select>
                <span>月</span>
                <b-form-select v-model="form.renew.end_day" :options="renewEndDayOptions" class="mx-2" :disabled="isShow"></b-form-select>
                <span>日</span>
              </div>
            </b-form-group>
            <!-- type == 3 月週期 -->
            <b-form-group
              v-if="form.renew.role == 3"
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="指定期限"
              label-for="period"
            >
              <b-input-group>
              <b-form-input v-model="form.renew.period" type="number" min="0" :disabled="isShow"></b-form-input>
                <b-input-group-append>
                  <b-input-group-text>個月內</b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="總消費額"
              label-for="amount"
            >
              <b-input-group prepend="NT$">
                <b-form-input
                  id="name"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="number"
                  min="0"
                  v-model="form.renew.amount"
                  :state="v$.form.renew.amount.$error ? false : null"
                  :disabled="isShow"
                ></b-form-input>
              </b-input-group>
              <b-form-invalid-feedback :state="!v$.form.renew.amount.$error"
                >此欄位為必填</b-form-invalid-feedback
              >
            </b-form-group>
            <small>*注意 : 當顧客訂單完成付款後，系統將於隔日凌晨依照升級條件計算會員等級。</small>
          </div>
        </section>
      </b-overlay>

      <div class="d-flex justify-content-center">
        <b-button class="mr-4" variant="outline-danger" @click="handleCancel"
          >返回</b-button
        >
        <b-overlay
          :show="isLoading && !isShow"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          v-if="!isShow"
        >
          <b-button variant="success" @click="handleSubmit">
            確認編輯
          </b-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { subDays } from "date-fns";
import PermissionChecker from "@/utils/PermissionChecker";
import { mapGetters } from "vuex";
import * as consts from "@/consts";

export default {
  components: {
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      consts,
      disabledDates: { to: subDays(new Date(), 1) },
      isShow: true,
      isLoading: false,
      form: {
        base: {
          role: 1,
          amount: 1,
          period: 1,
          start_month: 1,
          start_day: 1,
          end_month: 12,
          end_day: 31,
        },
        validity_period: 1,
        validity_permanent: 1, // 1是永久 0是有期限
        renew: {
          role: 1,
          amount: 1,
          period: 1,
          start_month: 1,
          start_day: 1,
          end_month: 12,
          end_day: 31,
        }
      },
      validityOptions: [
        { text: '永久會籍', value: 1 },
        { text: '設定有效期限', value: 0 }
      ],
      roleOptions: [
        { value: 1, text: "單次購物" },
        { value: 2, text: "固定週期" },
        { value: 3, text: "制定期限內購物" },
      ],
      monthOptions: Array.from({length: 12}, (_, i) => ({ value: i + 1, text: i + 1 })),
      baseStartDayOptions: Array.from({length: 31}, (_, i) => ({ value: i + 1, text: i + 1 })),
      baseEndDayOptions: Array.from({length: 31}, (_, i) => ({ value: i + 1, text: i + 1 })),
      renewStartDayOptions: Array.from({length: 31}, (_, i) => ({ value: i + 1, text: i + 1 })),
      renewEndDayOptions: Array.from({length: 31}, (_, i) => ({ value: i + 1, text: i + 1 })),
    };
  },
  watch: {
    "form.base.start_month"(value) {
      this.updateDayOptions('start', 'base', value);
    },
    "form.base.end_month"(value) {
      this.updateDayOptions('end', 'base', value);
    },
    "form.renew.start_month"(value) {
      this.updateDayOptions('start', 'renew', value);
    },
    "form.renew.end_month"(value) {
      this.updateDayOptions('end', 'renew', value);
    }
  },
  validations() {
    return {
      form: {
        base: {
          role: { required },
          amount: { required },
          period: {},
          start_month: {},
          start_day: {},
          end_month: {},
          end_day: {},
        },
        validity_period: {},
        validity_permanent: { required },
        renew: {
          role: { required },
          amount: { required },
          period: {},
          start_month: {},
          start_day: {},
          end_month: {},
          end_day: {},
        }
      },
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters("route", ["routeQuery"]),
  },
  methods: {
    async init() {
      try {
        this.isLoading = true;
        // call api
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handleSubmit() {
      try {
        const result = await this.v$.$validate();
        if (!result) return;

        this.isLoading = true;
        // call api
        this.$router.push({
          name: "CustomerLevelsList",
        });
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "", "error");
      } finally {
        this.isLoading = false;
      }
    },
    handleCancel() {
      this.$router.push({
        name: "CustomerLevelsList",
      });
    },
    updateDayOptions(type, formType, month) {
      const daysInMonth = new Date(new Date().getFullYear(), month, 0).getDate();
      const newDayOptions = Array.from({length: daysInMonth}, (_, i) => ({ value: i + 1, text: i + 1 }));

      if (type === 'start') {
        if (formType === 'base') {
          this.baseStartDayOptions = newDayOptions;
          if (this.selectedStartDay > daysInMonth) {
            this.selectedStartDay = daysInMonth;
          }
        } else {
          this.renewStartDayOptions = newDayOptions;
          if (this.selectedStartDay > daysInMonth) {
            this.selectedStartDay = daysInMonth;
          }
        }
      } else {
        if (formType === 'base') {
          this.baseEndDayOptions = newDayOptions;
          if (this.selectedEndDay > daysInMonth) {
            this.selectedEndDay = daysInMonth;
          }
        } else {
          this.renewEndDayOptions = newDayOptions;
          if (this.selectedEndDay > daysInMonth) {
            this.selectedEndDay = daysInMonth;
          }
        }
      }
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
